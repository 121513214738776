<template>
  <div id="app">
    <div class="banner">
      <img :src="this.url.imgUrl + bannerImg" alt="立优教育" />
    </div>
    <div class="down">
      <div class="login_card">
        <!-- 忘记密码卡片 -->
        <!-- 第一步验证 -->
        <div class="login_box" id="step1" v-if="oneStepStatus == true">
          <ul class="forget_pwd_top">
            <li>继续<router-link to="login">登录</router-link></li>
            <li><router-link :to="'/'">返回首页</router-link></li>
          </ul>
          <div class="login">
            <!-- 第一步 -->
            <div class="one_step">
              <!-- 步骤条 -->
              <img
                class="step1"
                src="../../assets/img/stpe1.png"
                alt="立优教育"
              />
              <div class="register_ipt">
                <input
                  type="text"
                  placeholder="请输入手机号码"
                  v-model="form.mobile"
                  maxlength="11"
                />
                <img src="../../assets/img/phone.png" alt="立优教育" />
                <div class="no_tel">
                  手机号不存在，点击<a href="/pc/register.html">【注册】</a>
                </div>
              </div>
              <!-- 图形验证码 -->
              <div class="register_ipt">
                <div class="img_code_box">
                  <input
                    type="text"
                    placeholder="图形验证码"
                    maxlength="4"
                    v-model="form.verification_code"
                  />
                  <img src="../../assets/img/pic.png" alt="立优教育" />
                  <!-- @click="changeImg" -->
                  <div class="code_img" style="left: 301px">
                    <img
                      style="width: 130px; height: 38px"
                      id="redom"
                      :src="captchaImg"
                      alt="立优教育"
                      @click="getImgCode"
                    />
                  </div>
                </div>
              </div>
              <!-- 短信验证码 -->
              <div class="register_ipt get_code">
                <div>
                  <input
                    type="text"
                    placeholder="获取短信验证码"
                    maxlength="4"
                    v-model="form.code"
                  />
                  <img src="../../assets/img/word.png" alt="立优教育" />
                  <button
                    class="send_code"
                    :disabled="disabled"
                    @click="sendCode"
                  >
                    {{ btntxt }}
                  </button>
                  <img
                    style="width: 19px; height: 19px"
                    src="../../assets/img/suo.png"
                    alt="立优教育"
                  />
                </div>
                <!-- <div class="code_wrong">验证码输入错误</div> -->
              </div>
              <!-- 下一步 -->
              <button class="next" type="button" @click="nextStep(1)">
                下一步
              </button>
            </div>
          </div>
        </div>
        <!-- 第二步重置密码 -->
        <div class="login_box" id="step2" v-if="twoStepStatus == true">
          <ul class="forget_pwd_top">
            <li>继续<router-link to="login">登录</router-link></li>
            <li><router-link :to="'/'">返回首页</router-link></li>
          </ul>
          <div class="login">
            <!-- 第二步 -->
            <div class="one_step">
              <!-- 步骤条 -->
              <img class="step1" src="../../assets/img/2.png" alt="立优教育" />
              <div class="register_ipt">
                <span>新密码:</span>
                <div>
                  <el-input
                    class="el_ipt"
                    v-model="form.newPwd"
                    placeholder="请输入6-20位密码"
                  ></el-input>
                  <img
                    id="pass"
                    :src="passImg"
                    v-if="form.newPwd == form.surePwd && form.newPwd.length > 6"
                    alt="立优教育"
                    style="width: 20px; height: 20px"
                  />
                </div>
              </div>
              <div class="register_ipt">
                <span>确认密码:</span>
                <div>
                  <el-input
                    class="el_ipt"
                    v-model="form.surePwd"
                    placeholder="请再次输入密码"
                  ></el-input>
                  <img
                    id="pass"
                    :src="passImg"
                    v-if="form.newPwd == form.surePwd && form.newPwd.length > 6"
                    alt="立优教育"
                    style="width: 20px; height: 20px"
                  />
                </div>
              </div>
              <!-- 下一步 -->
              <button class="next" type="button" @click="nextStep(2)">
                下一步
              </button>
            </div>
          </div>
        </div>
        <!-- 第三步完成 -->
        <div class="login_box" id="step3" v-if="threeStepStatus == true">
          <ul class="forget_pwd_top">
            <li>继续<router-link to="login">登录</router-link></li>
            <li><router-link :to="'/'">返回首页</router-link></li>
          </ul>
          <div class="login">
            <!-- 第三步 -->
            <div class="one_step">
              <!-- 步骤条 -->
              <img class="step1" src="../../assets/img/3.png" alt="立优教育" />
              <div class="pwd_box">
                <div>新密码已生效!</div>
                <div>
                  <p>{{ count }}</p>
                  秒后自动返回登录页。
                </div>
                <div>（如果长期未自动返回请手动点击返回登录页）</div>
              </div>

              <!-- 返回登录页 -->

              <router-link :to="{ name: 'Login' }">
                <button class="next" type="button" @click="nextStep(3)">
                  返回登录页
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="record_number">
        备案号：湘ICP备 2021000943号 <br />
        联系地址：长沙高新开发区麓谷大道658号湖南麓谷信息港15002-3 <br />
        联系电话：400-656-6816  服务时间：周一至周五9:00-12:00 14:00-17:00<br />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import crypto from "crypto";
import qs from "qs";
export default {
  data() {
    return {
      bannerImg: "/assets/img/pwd_banner.png",
      captchaImg: require("../../assets/img/click.png"), // 图形验证码
      disabled: false,
      time: 60, // 倒计时
      count: "", //忘记密码倒计时
      btntxt: "获取短信验证码",
      form: {
        mobile: "", // 用户手机号
        verification_code: "", // 图形验证码
        nonce_str: "", // 随机串
        pwd: "",
        code: "", // 短信验证码
        newPwd: "", //新密码
        surePwd: "", //确认密码
      },
      oneStepStatus: true,
      twoStepStatus: false,
      threeStepStatus: false,
      passImg: require("../../assets/img/pass.png"),
    };
  },
  methods: {
    // getRandom(){}
    // 点击切换图形验证码
    getImgCode() {
      var md5 = crypto.createHash("md5");
      md5.update(Math.ceil(Math.random() * 10) + "" + Date.now().toString());
      this.form.nonce_str = md5.digest("hex");
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$message.error({ duration: 1500, message: "请输入正确手机号!" });
        return false;
      } else {
        this.captchaImg =
          this.url.apiUrl +
          "/api/verification_code/verification?nonce_str=" +
          this.form.nonce_str +
          "&mobile=" +
          this.form.mobile +
          "&w=" +
          130 +
          "&h=" +
          38;
      }
    },
    // 发送短信验证码
    sendCode() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$message.error({ duration: 1500, message: "请输入正确手机号!" });
        return false;
      } else {
        var data = {
          mobile: this.form.mobile,
          event: "resetpwd",
          verification_code: this.form.verification_code,
          nonce_str: this.form.nonce_str,
        };
        api.send(this.qs.stringify(data)).then((res) => {
        });
      }
      this.time = 60;
      this.timer();
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "发送验证码";
        this.disabled = false;
      }
    },
    // // 立即注册
    // registerNow() {
    //   var data = {
    //     mobile: this.form.mobile,
    //     password: 123456,
    //     subject_id: 1,
    //     code: this.form.code,
    //   };
    //   api.register(data).then((res) => {
    //     if (res.data.code == 1) {
    //       this.$message.success({duration:1500,message:"注册成功"});
    //       // location.href = "/pc/index.html?page_id = 1";
    //       this.$router.push({name:"Login",params:{is_infoid:1}})
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   });
    // },

    // 下一步操作
    nextStep(id) {
      // 第一步
      const reg = /^1[3-9][0-9]{9}$/;
      if (id == 1) {
        if (
          this.form.mobile == "" ||
          this.form.mobile <= 10 ||
          !reg.test(this.form.mobile)
        ) {
          this.$message.error({ duration: 1500, message: "请输入正确手机号!" });
          return false;
        } else if (this.form.verification_code == "") {
          this.$message.error({
            duration: 1500,
            message: "请输入正确的图形验证码!",
          });
          return;
        } else if (this.form.code == "") {
          this.$message.error({
            duration: 1500,
            message: "请输入正确的短信验证码!",
          });
          return;
        }
        this.oneStepStatus = false;
        this.twoStepStatus = true;
      } else if (id == 2) {
        // 第二步操作
        if (this.form.newPwd == "" && this.form.newPwd.length < 6) {
          this.$message.error({
            duration: 1500,
            message: "请输入新密码,并且密码长度不低于6位!",
          });
          return;
        } else if (this.form.surePwd == "" && this.form.surePwd.length < 6) {
          this.$message.error({
            duration: 1500,
            message: "请输入确认密码,并且密码长度不低于6位!",
          });
          return;
        } else if (this.form.newPwd != this.form.surePwd) {
          this.$message.error({
            duration: 1500,
            message: "密码不一致,请重新输入!",
          });
          return;
        } else {
          // var formdata = new FormData();
          var data = {
            mobile: this.form.mobile,
            captcha: this.form.code,
            newpassword: this.form.newPwd,
          };
          // var aa = qs.stringify(data)
          api.resetpwd(this.qs.stringify(data)).then((res) => {
            const TIME_COUNT = 3;
            if (parseInt(res.data.code) == 1) {
              this.$message.success({ duration: 1500, message: res.data.msg });
              this.oneStepStatus = false;
              this.twoStepStatus = false;
              this.threeStepStatus = true;
              this.count = TIME_COUNT;
              let clock = window.setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.$router.push({ name: "Login" });
                }
              }, 1000);
            } else {
              this.$message.success({ duration: 1500, message: res.data.msg });
            }
          });
        }
      }
    },
    getCount() {
      const TIME_COUNT = 3;
      this.count = TIME_COUNT;
      let clock = window.setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
        } else {
          this.$router.push("/login");
        }
      }, 1000);
    },
  },
  created() {
    // this.getCount();
  },
};
</script>

<style lang="scss" scoped>
#head_nav {
  display: none;
}

.login_card_none {
  display: none;
}

/* #app {
    width: 100%;
    height: 100vh;
} */

#app .banner {
  width: 100%;
  height: 490px;
  position: relative;
}

#app .banner img {
  width: 100%;
  height: 490px;
  object-fit: cover;
}

#app .down {
  position: relative;
  width: 100%;
  height: 590px;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) inset; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04) inset; */
  margin-bottom: 30px;
}

#app .down .login_card {
  position: absolute;
  top: -302px;
  left: 0;
  right: 0;
  width: 1100px;
  height: 529px;
  /* background: pink; */
  margin: auto;
  z-index: 1;
}

.login_card .login_title div:nth-child(1) {
  width: 402px;
  height: 76px;
  font-size: 80px;
  font-family: DFPSongW12-GB;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  letter-spacing: 10px;
}

.login_card .login_title div:nth-child(2) {
  width: 400px;
  height: 11px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  letter-spacing: 17px;
}

#app .down .record_number {
  // width: 511px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: center;
  margin: auto;
}

.login_card .login_box {
  width: 1101px;
  height: 526px;
  background: #ffffff;
  /* background: pink; */
  box-shadow: 0px 0px 35px 0px rgba(173, 192, 238, 0.6);
  border-radius: 20px;
  margin-top: 140px;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

#step2,
#step3 {
  height: 460px;
}
/deep/#step2 .el-input__inner {
  width: 284px;
  height: 48px;
}
.login_card .forget_pwd_top {
  width: 100%;
  height: 44px;
  /* background: pink; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.login_card .forget_pwd_top li {
  width: 74px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}

.login_card .forget_pwd_top li a {
  color: #3e7eff;
}

.login_card .forget_pwd_top li:nth-child(2) {
  margin: 0 35px 0 19px;
}

.login_card .login_box .login {
  width: 388px;
  margin: 16px auto 0;
}

.login .register_ipt {
  position: relative;
  margin-bottom: 30px;
}

.login .register_ipt input {
  width: 353px;
  height: 48px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding-left: 40px;
  outline: none;
  font-size: 16px;
  font-family: PingFang SC;
  // font-weight: 400;
  color: #333;
}

.login .register_ipt input::placeholder {
  font-family: none;
  color: #999;
}
/deep/.el-input__inner{
  font-size: 16px;
  color: #333;
}
/deep/.el-input__inner::placeholder {
  font-size: 16px;
  color: #999;
}
.login .register_ipt img {
  position: absolute;
  top: 16px;
  left: 12px;
  width: 22px;
  height: 19px;
  z-index: 9;
}

.login .get_code input {
  width: 204px;
}

.login .get_code .send_code {
  /* display: inline-block; */
  width: 135px;
  height: 51px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #3e7eff;
  line-height: 48px;
  text-align: center;
  background: #fff;
}
.login .get_code div {
  display: flex;
  justify-content: space-between;
}

.treaty_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.treaty_box li {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 300;
  color: #333333;
}

.treaty_box li:nth-child(1) span {
  font-size: 14px;
  font-family: PingFang SC;
  color: #3e7eff;
}

.treaty_box li:nth-child(2) a {
  font-size: 14px;
  font-family: PingFang SC;
  color: #3e7eff;
  margin-top: -16px;
}

.register_ipt .img_code_box {
  position: relative;
}

.register_ipt .img_code_box .code_img {
  position: absolute;
  top: -3px;
  left: 290px !important;
}

/* 步骤条 */

.login .step1 {
  width: 100%;
  margin-bottom: 48px;
}

/* 手机号不存在 */

.login .no_tel {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 4px;
  display: none;
}

.login .no_tel a {
  color: #ed5736;
}

/* 验证码错误 */

.login .code_wrong {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ed5736;
  margin-top: 4px;
  display: none;
}

/* 下一步 */

.login .next {
  width: 388px;
  height: 48px;
  background: linear-gradient(259deg, #3e7eff 0%, #2765f3 100%);
  box-shadow: 0px 9px 22px 0px rgba(81, 133, 240, 0.8);
  border-radius: 24px;
  border: none;
  outline: none;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 48px;
  margin-top: 15px;
}

/* 步骤二 */

#step2 .register_ipt {
  width: 388px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#step2 .register_ipt span {
  display: block;
  width: 90px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

#step2 .register_ipt input {
  width: 284px;
  height: 48px;
  position: relative;
}

#pass {
  position: absolute;
  top: 16px;
  left: 352px;
}

/* 步骤三 */

#step3 .pwd_box {
  width: 252px;
  height: 61px;
  margin: 40px auto 80px;
}

.pwd_box div {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.pwd_box div:nth-child(2) {
  display: flex;
  justify-content: center;
}

.pwd_box div:nth-child(2) p {
  color: #ed5736;
}

.pwd_box div:nth-child(3) {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ed5736;
}

#redom {
  top: 10px;
  left: -36px;
}
</style>
